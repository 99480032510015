import styles from "./sidebar.module.css";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Config } from "../../config";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useRoleBasedAccess } from "../../hooks/useRoleBasedAccess";
import VTPStyles from "../../styles/vtpStyles";
import { ReactComponent as SidebarKandaLogo } from "../../assets/img/kanda-logo-sidebar.svg";
import { ReactComponent as HomeIcon } from "../../assets/navigation/home.svg";
import { ReactComponent as PersonIcon } from "../../assets/navigation/person.svg";
import { ReactComponent as DownloadIcon } from "../../assets/navigation/file_download.svg";
import { ReactComponent as KeyIcon } from "../../assets/navigation/key.svg";
import { ReactComponent as FeedbackIcon } from "../../assets/navigation/feedback.svg";
import { ReactComponent as ContactSupportIcon } from "../../assets/navigation/contact_support.svg";
import { ReactComponent as LinkIcon } from "../../assets/navigation/link.svg";
import { ReactComponent as BuildingIcon } from "../../assets/icons/building.svg";
import { useAppContext } from "../../context/app-context";
import AuthUtilities from "../../lib/authUtilities";
import VTPSimpleConfirmationModal, {
  ConfirmationHeader,
} from "../modal/vtpSimpleConfirmationModal";
import VTPButton, { ButtonSize, ButtonType } from "../base/button";
import { Tenant, UserRoles } from "../../lib/apiModels";
import useClickOutside from "../../hooks/useClickOutside";
import VTPToggleSwitch from "../base/form/vtpToggleSwitch";
import { useVTPCloud } from "../../context/vtpCloud-context";
import { useTranslation } from "react-i18next";

enum OptionBarState {
  Closed,
  OrganisationSelection,
  AccountSettings,
  Support,
}

const getAccessDurationDate = (
  tenant: Tenant | undefined
): Date | undefined => {
  if (tenant && tenant.supportAccess) {
    const dateString = tenant.supportAccess;
    const parsedDate = new Date(dateString);

    // Compare parsed date with current UTC date
    const currentUtcDate = new Date();
    if (parsedDate.getTime() >= currentUtcDate.getTime()) {
      return parsedDate;
    } else {
      // Access duration has expired
      return undefined;
    }
  }
};

// Format Date to "MMM dd, yyyy, hh:mmA"
const formatDateString = (date: Date | undefined): string => {
  if (!date) return "";

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return date.toLocaleString("en-US", options);
};

const OptionBar = (props: {
  state: OptionBarState;
  externalRefs: React.RefObject<HTMLDivElement>[];
  completed: () => any;
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  let isModalOpen = false;
  useClickOutside(props.externalRefs.concat([ref]), () => {
    if (!isModalOpen) {
      props.completed();
    }
  });

  const AccountOptions = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { logout } = useAuth0();
    const cloudAPI = useVTPCloud();

    return (
      <div className={`${styles.optionBar} ${styles.userOptions}`}>
        <span
          className={`${styles.optionBarHeader} ${VTPStyles.Typography.Headers.H3EyebrowSmall}`}
        >
          {t("sidebar.userItems.account")}
        </span>
        <div
          className={`${styles.optionBarItems} ${VTPStyles.Typography.Buttons.SizeSmall}`}
        >
          <span
            className={`${styles.optionBarItem}`}
            onClick={() => cloudAPI.logout().finally(() => logout())}
          >
            {t("sidebar.userItems.logout")}
          </span>
          <span
            className={`
                            ${styles.optionBarItem} 
                            ${
                              location.pathname == Config.accountSettings
                                ? styles.selected
                                : ""
                            }
                        `}
            onClick={() => {
              navigate(Config.accountSettings);
              props.completed();
            }}
          >
            {t("sidebar.userItems.settings")}
          </span>
        </div>
      </div>
    );
  };

  const SupportOptions = () => {
    const { getSelectedTenant, setUserTenants } =
      useAppContext();
    const { updateTenant } = useVTPCloud();
    const { userHasRequiredRoleOrHigher } = useRoleBasedAccess();

    const [accessDuration, setAccessDuration] = useState(
      getAccessDurationDate(getSelectedTenant)
    );
    const [updatedTenant, setUpdatedTenant] = useState<Tenant>();

    useEffect(() => {
      if (updatedTenant) {
        // Update cache data, this should not cause re-render.
        getSelectedTenant!.supportAccess = updatedTenant.supportAccess;
        setUserTenants((prevValue) => {
          const tenant = prevValue.find((t) => t.id == updatedTenant.id);
          tenant!.supportAccess = updatedTenant.supportAccess;
          return prevValue;
        });
      }
    }, [updatedTenant]);

    return (
      <div className={`${styles.optionBar} ${styles.userOptions}`}>
        <span
          className={`${styles.optionBarHeader} ${VTPStyles.Typography.Headers.H3EyebrowSmall}`}
        >
          Support
        </span>
        <div
          className={`${styles.optionBarItems} ${VTPStyles.Typography.Buttons.SizeSmall}`}
        >
          <span
            className={`${styles.optionBarItem} ${styles.optionBarLinkItem}`}
            onClick={() => window.open("https://www.kanda.dk/contact")}
          >
            {t("sidebar.getSupportItems.contactSupport")} <LinkIcon />
          </span>
          <span
            className={`${styles.optionBarItem} ${styles.optionBarLinkItem}`}
            onClick={() => window.open("https://www.kanda.dk/knowledge")}
          >
            {t("sidebar.getSupportItems.knowledgeHub")} <LinkIcon />
          </span>
          {userHasRequiredRoleOrHigher(UserRoles.RegularUser) ? (
            <div className={`${styles.remoteAccessToggleGroup}`}>
              <label>
                {t("sidebar.getSupportItems.remoteAccess")}{" "}
                <VTPToggleSwitch
                  small={true}
                  checked={!!accessDuration}
                  onChange={(val) => {
                    updateTenant({
                      id: getSelectedTenant!.id,
                      supportAccess: val,
                    }).then((res) => {
                      setAccessDuration(getAccessDurationDate(res));
                      setUpdatedTenant(res);
                    });
                  }}
                />
              </label>
              <div
                className={`${styles.remoteAccessDescription} ${VTPStyles.Typography.Headers.H4Caption} ${VTPStyles.Color.Text.SecondaryColor}`}
              >
                {t("sidebar.getSupportItems.remoteAccessDescription")}
              </div>
              {accessDuration ? (
                <div
                  className={`${styles.remoteAccessInfo} ${VTPStyles.Typography.Headers.H4Caption}`}
                >
                  <div className={`${VTPStyles.Color.Text.SecondaryColor}`}>
                    Access enabled until
                  </div>
                  <div className={`${VTPStyles.Color.Text.PrimaryColor}`}>
                    {formatDateString(accessDuration)}
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const OrganisationSelection = () => {
    const { getUserTenants, getSelectedTenant, setSelectedTenant } =
      useAppContext();
    const [pendingTenantSwitch, setPendingTenantSwitch] = useState<Tenant>();
    const navigate = useNavigate();

    useEffect(() => {
      isModalOpen = !!pendingTenantSwitch;
    }, [pendingTenantSwitch]);

    function updateSelectedTenant(tenant?: Tenant): void {
      if (tenant) {
        if (getSelectedTenant?.id !== tenant?.id) {
          setSelectedTenant(tenant);
          navigate(Config.users);
        }
        props.completed();
      }
      setPendingTenantSwitch(undefined);
    }

    return (
      <div className={`${styles.optionBar} ${styles.organisationOptions}`}>
        <div
          className={`${styles.optionBarHeader} ${VTPStyles.Typography.Headers.H3EyebrowSmall}`}
        >
          {t("sidebar.tenantPicker.header")}
        </div>
        <div
          className={`${styles.optionBarItems} ${VTPStyles.Typography.Buttons.SizeSmall}`}
        >
          {getUserTenants
            .sort((a, b) => a.displayName.localeCompare(b.displayName))
            .map((t, pos) => {
              return (
                <span
                  key={t.id}
                  className={`
                                        ${styles.optionBarItem}
                                        ${
                                          getSelectedTenant?.id === t.id
                                            ? styles.selected
                                            : ""
                                        }
                                    `}
                  onClick={() => {
                    setPendingTenantSwitch(t);
                  }}
                >
                  {t.displayName}
                </span>
              );
            })}
        </div>
        {pendingTenantSwitch ? (
          <VTPSimpleConfirmationModal
            header={
              <ConfirmationHeader
                icon={<BuildingIcon />}
                title={t("sidebar.tenantPicker.switchOrganisationModal.header")}
              />
            }
            body={
              <div>
                <div>
                  {t("sidebar.tenantPicker.switchOrganisationModal.text1", {
                    organisationName: pendingTenantSwitch.displayName,
                  })}
                </div>
                <div>
                  {t("sidebar.tenantPicker.switchOrganisationModal.text2")}
                </div>
              </div>
            }
            buttons={[
              <VTPButton
                key={"cancel"}
                size={ButtonSize.Medium}
                type={ButtonType.Tertiary}
                onClick={() => setPendingTenantSwitch(undefined)}
              >
                {t("common.inputs.cancel")}
              </VTPButton>,
              <VTPButton
                key={"confirm"}
                size={ButtonSize.Medium}
                type={ButtonType.Primary}
                onClick={() => updateSelectedTenant(pendingTenantSwitch)}
              >
                {t("common.inputs.confirm")}
              </VTPButton>,
            ]}
          />
        ) : null}
      </div>
    );
  };

  const renderOptions = () => {
    switch (props.state) {
      case OptionBarState.AccountSettings:
        return <AccountOptions />;
      case OptionBarState.OrganisationSelection:
        return <OrganisationSelection />;
      case OptionBarState.Support:
        return <SupportOptions />;
      default:
        return null;
    }
  };

  return (
    <div
      ref={ref}
      className={`${styles.optionBarContainer} no-text-select ${
        props.state != OptionBarState.Closed ? styles.open : ""
      }`}
    >
      {renderOptions()}
    </div>
  );
};

const Sidebar = () => {
  const { t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { getSelectedTenant } = useAppContext();
  const { userHasRequiredRoleOrHigher } = useRoleBasedAccess();
  const { getUserProfile } = useAppContext();
  const [optionsState, setOptionsState] = useState(OptionBarState.Closed);

  const tenantPickerRef = useRef<HTMLDivElement>(null);
  const userOptionsRef = useRef<HTMLDivElement>(null);

  const updateState = (newState: OptionBarState) => {
    setOptionsState((oldValue) =>
      oldValue === newState ? OptionBarState.Closed : newState
    );
  };

  const tenantPicker = () => {
    return (
      <div
        ref={tenantPickerRef}
        className={`
                    ${styles.sidebarItem} 
                    ${styles.tenantPicker} 
                     no-text-select 
                    ${VTPStyles.Typography.Buttons.SizeSmall}
                    ${
                      optionsState === OptionBarState.OrganisationSelection
                        ? styles.selected
                        : ""
                    }
                `}
        onClick={(e) => {
          e.preventDefault();
          updateState(OptionBarState.OrganisationSelection);
        }}
      >
        <HomeIcon />
        {getSelectedTenant?.displayName}
      </div>
    );
  };

  const appNavigationItems = () => {
    const appNavigationItem = (
      title: string,
      link: string,
      requiredRole?: UserRoles
    ) => {
      // Check if adminRequired is true and the user is not an admin
      if (requiredRole && !userHasRequiredRoleOrHigher(requiredRole)) {
        return null;
      }

      return (
        <a
          href={link}
          draggable={false}
          className={`
                ${styles.sidebarItem} 
                ${VTPStyles.Typography.Buttons.SizeSmall}
                ${location.pathname == link ? styles.selected : ""}
              `}
          onClick={(e) => {
            if (link) {
              e.preventDefault();
              navigate(link);
            }
          }}
        >
          {title}
        </a>
      );
    };

    return (
      <div className={styles.appNavigation}>
        {Config.enableAnalytics
          ? appNavigationItem(
              t("sidebar.items.analytics"),
              Config.analytics,
              UserRoles.RegularUser
            )
          : null}
        {appNavigationItem(
          t("sidebar.items.users"),
          Config.users,
          UserRoles.RegularUser
        )}
        {Config.enableDashboardCoursesPage
          ? appNavigationItem(t("sidebar.items.courses"), Config.dashboardRoute)
          : null}
        {Config.enableDashboardSubscriptionsPage
          ? appNavigationItem(
              t("sidebar.items.subscriptions"),
              Config.subscriptions,
              UserRoles.Administrator
            )
          : null}
        {appNavigationItem(t("sidebar.items.files"), Config.files)}
        {getUserProfile?.isSupport
          ? appNavigationItem(t("sidebar.items.support"), Config.support)
          : null}
      </div>
    );
  };

  const userNavigationItems = () => {
    const userNavigationItem = (
      content: ReactElement,
      action: () => any,
      selected?: boolean
    ) => {
      return (
        <div
          className={`
                        no-text-select 
                        ${styles.sidebarItem} 
                        ${selected ? styles.selected : ""}
                        ${VTPStyles.Typography.Buttons.SizeSmall}
              `}
          onClick={action}
        >
          {content}
        </div>
      );
    };

    return (
      <div className={styles.userNavigation} ref={userOptionsRef}>
        {userNavigationItem(
          <>
            <PersonIcon /> {getUserProfile?.fullName}
          </>,
          () => updateState(OptionBarState.AccountSettings),
          optionsState === OptionBarState.AccountSettings
        )}
        {Config.enableDashboardGetSupportTab
          ? userNavigationItem(
              <>
                <ContactSupportIcon /> {t("sidebar.items.getSupport")}
              </>,
              () => updateState(OptionBarState.Support),
              optionsState === OptionBarState.Support
            )
          : null}
        {Config.enableDashboardDownloadAppLink
          ? userNavigationItem(
              <>
                <DownloadIcon /> {t("sidebar.items.downloadApp")} <LinkIcon />
              </>,
              () => window.open("https://www.kanda.dk/download")
            )
          : null}
        {userNavigationItem(
          <>
            <KeyIcon /> {t("sidebar.items.inputCode")} <LinkIcon />
          </>,
          () => AuthUtilities.StartDeviceCodeInput()
        )}
        {userNavigationItem(
          <>
            <FeedbackIcon /> {t("sidebar.items.giveFeedback")} <LinkIcon />
          </>,
          () =>
            window.open(
              "https://survey-eu1.hsforms.com/1yM5tbw_JQ2OgY57MohLAZQ2zqle"
            )
        )}
      </div>
    );
  };

  return (
    <div className={`${styles.sidebarContainer}`}>
      <OptionBar
        state={optionsState}
        externalRefs={[tenantPickerRef, userOptionsRef]}
        completed={() => setOptionsState(OptionBarState.Closed)}
      />
      <div className={`${styles.sidebar}`}>
        <div className={`${styles.sidebarLogo}`}>
          <SidebarKandaLogo />
        </div>
        {tenantPicker()}
        {appNavigationItems()}
        {userNavigationItems()}
      </div>
    </div>
  );
};

export default Sidebar;
