import React from "react";
import {StylingProps} from "../../lib/common";
import {Outlet} from "react-router-dom";
import styles from "./headerLayout.module.css"
import { ReactComponent as KandaLogo } from "../../assets/img/kanda-logo-small.svg";

const HeaderLayout = (props: StylingProps) => {
    return (
      <div className={styles.container}>
          <div className={styles.header}><KandaLogo /></div>
          <div className={styles.content}>
              <Outlet />
          </div>
      </div>  
    );
}

export default HeaderLayout;