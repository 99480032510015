import React, { useEffect, useRef, useState } from "react";
import styles from "./uploadUserFilesModal.module.css";
import modalStyles from "../modal/vtpSimpleModal.module.css";
import VTPSimpleModal from "../modal/vtpSimpleModal";
import VTPStyles from "../../styles/vtpStyles";
import VTPButton, { ButtonSize, ButtonType } from "../base/button";
import { ReactComponent as UploadIcon } from "../../assets/icons/upload.svg";
import { ReactComponent as TrashbinIcon } from "../../assets/trashbin.svg";
import { ReactComponent as KandaLogo } from "../../assets/img/kanda-logo-modal.svg";
import { ReactComponent as RefreshIcon } from "../../assets/icons/refresh.svg";
import VTPDropdown, { IDropdownOption } from "../base/form/vtpDropdown";
import CommonUtilities from "../../lib/common";
import { GetFileCategoryIcon } from "../../pages/filesPage";
import {
  FileUploadRequest,
  useUploadManagerContext,
} from "../../context/useUploadManager-context";
import {
  FileSharingOptions,
  IStorageState,
  UserRoles,
} from "../../lib/apiModels";
import { useVTPCloud } from "../../context/vtpCloud-context";
import { useRoleBasedAccess } from "../../hooks/useRoleBasedAccess";
import VTPTextInput, { BorderType } from "../base/form/vtpTextInput";
import CloseButton from "../common/closeButton";
import { useTranslation } from "react-i18next";

interface PendingFileRequestUpload extends FileUploadRequest {
  fileTooBig?: boolean;
  notEnoughSpace?: boolean;
  isError?: boolean;
}

interface Props {
  onSubmit?: () => void;
  onCancel?: () => void;
  courseFileUploadOptions?: {
    folderName?: string;
    scenarioId: string;
  };
}

const UploadUserFilesModal = (props: Props) => {
  const { t } = useTranslation();
  const { getStorageState } = useVTPCloud();
  const { queueFileUploads } = useUploadManagerContext();
  const { userHasValidRole } = useRoleBasedAccess();
  const fileInput = useRef<HTMLInputElement>(null);
  const [uploadRequests, setUploadRequests] = useState<
    PendingFileRequestUpload[]
  >([]);

  // Allowed file types
  const allowedTypes =
    "image/png, image/jpeg, video/mp4, video/avi, .pdf".split(", ");

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      const filteredFiles = Array.from(files).filter(
        (file) =>
          allowedTypes.includes(file.type) ||
          allowedTypes.some((type) => file.name.endsWith(type.replace(".", "")))
      );
      filesSelected(filteredFiles);
    }
  };

  const [storageState, setStorageState] = useState<IStorageState>({
    used: 0,
    total: 0,
    maxFileSize: 0,
  });

  useEffect(() => {
    getStorageState()
      .then((res) => {
        setStorageState(res);
      })
      .catch();
  }, []);

  const getFolder = () => {
    if (props.courseFileUploadOptions?.folderName)
      return `/${props.courseFileUploadOptions.folderName}/`;

    return "";
  };

  const filesSelected = (files: File[]) => {
    if (files) {
      const newUploadRequests: PendingFileRequestUpload[] = files.map((f) => {
        return {
          file: f,
          fileName: getFolder() + f.name,
          sharingOption: FileSharingOptions.Private,
          contentType: f.name.split(".").pop()!,
          scenarioId: props.courseFileUploadOptions?.scenarioId,
        };
      });

      newUploadRequests.forEach((item) => {
        // Get total size that will be uploaded.
        const totalUploadSize = uploadRequests
          .filter((u) => u.fileTooBig !== true)
          .reduce((a, b) => a + b.file.size, 0);

        // Check if file too big to upload.
        if (item.file.size > storageState.maxFileSize) {
          item.fileTooBig = true;
          item.isError = true;
        }
        // Check if there is enough space for upcoming file.
        else if (
          totalUploadSize + storageState.used + item.file.size >
          storageState.total
        ) {
          item.notEnoughSpace = true;
          item.isError = true;
        }

        // Push item to collection.
        uploadRequests.push(item);
      });

      setUploadRequests(uploadRequests.map((u) => u));
    }
  };

  const handleFileUpload = () => {
    queueFileUploads(uploadRequests);

    props.onSubmit?.();
  };

  const handleFileNameChange = (pos: number, fileName: string) => {
    setUploadRequests((prevState) => {
      prevState[pos].fileName = getFolder() + fileName;
      return prevState.map((u) => u);
    });
  };

  const handleFileVisibilityChange = (
    pos: number,
    sharingOptions: FileSharingOptions
  ) => {
    setUploadRequests((prevState) => {
      prevState[pos].sharingOption = sharingOptions;
      return prevState.map((u) => u);
    });
  };

  const handleRetry = (pendingRequest: PendingFileRequestUpload) => {
    delete uploadRequests[uploadRequests.indexOf(pendingRequest)];
    filesSelected([pendingRequest.file]);
  };

  const dropdownOptions = (): IDropdownOption[] => {
    // Trainee options.
    const options = [
      {
        name: t("filesPage.uploadFileModal.visibilityOptions.private"),
        value: FileSharingOptions.Private.toString(),
      },
    ];

    // Inherit all options from trainee.
    const instructorOptions = [
      {
        name: t("filesPage.uploadFileModal.visibilityOptions.instructor"),
        value: FileSharingOptions.Instructor.toString(),
      },
    ].concat(options);

    // Inherit all options from instructor and trainee.
    const adminOptions = [
      {
        name: t("filesPage.uploadFileModal.visibilityOptions.everyone"),
        value: FileSharingOptions.Everyone.toString(),
      },
    ].concat(instructorOptions);

    // User has instructor role.
    if (userHasValidRole([UserRoles.RegularUser])) {
      return instructorOptions;
    }

    // User has admin role.
    if (userHasValidRole([UserRoles.Administrator])) {
      return adminOptions;
    }

    // Trainee role.
    return options;
  };

  const convertBytesToGB = (bytes: number) => {
    if (bytes === 0) {
      return 0;
    }

    return Math.max(0.01, bytes / Math.pow(1000, 3)).toFixed(2);
  };

  const fileList = () => {
    return (
      <div>
        {uploadRequests.length == 0 ? (
          <div className={styles.kandaLogo}>
            <KandaLogo />
          </div>
        ) : (
          <div className={`${styles.fileList}`}>
            {uploadRequests
              .sort((value) => {
                return value.isError ? -1 : 1; // `true` values first
              })
              .map((req, pos) => {
                const file = req.file;
                return (
                  <div key={pos} className={`${styles.fileListRow}`}>
                    <span className={`${styles.fileIconColumn}`}>
                      {GetFileCategoryIcon(file.name.split(".").pop())}
                    </span>
                    <div className={styles.fileNameColumn}>
                      {req.isError ? (
                        <>
                          <div
                            title={file.name}
                            className={`${styles.fileName} 
                      ${VTPStyles.Typography.Body.Small} 
                      ${VTPStyles.Color.Text.PrimaryColor} 
                      ${styles.errorMessage}`}
                          >
                            {file.name}
                          </div>
                          <div
                            className={`${styles.errorMessage} ${VTPStyles.Typography.Headers.H4Caption}`}
                          >
                            {req.fileTooBig
                              ? ` ${t(
                                  "filesPage.uploadFileModal.fileMaxSizeError",
                                  {
                                    maxFileSize: CommonUtilities.FormatFileSize(
                                      storageState.maxFileSize
                                    ),
                                  }
                                )}`
                              : t(
                                  "filesPage.uploadFileModal.storageSpaceError"
                                )}
                          </div>
                        </>
                      ) : (
                        <VTPTextInput
                          className={styles.fileNameInput}
                          value={file.name}
                          borderStyle={BorderType.RoundBorder}
                          onChange={(e) =>
                            handleFileNameChange(pos, e.target.value)
                          }
                        />
                      )}
                    </div>
                    {/* If there is error on upload validation or file scenario file, don't show sharing options dropdown. */}
                    {req.isError ||
                    props.courseFileUploadOptions ? null : userHasValidRole([ // In case if user instructor, just show private user-file label.
                        UserRoles.Guest,
                      ]) ? (
                      <span
                        className={`${styles.availabilityLabel} ${VTPStyles.Typography.Headers.H4Caption}`}
                      >
                        {!props.courseFileUploadOptions
                          ? t(
                              "filesPage.uploadFileModal.visibilityOptions.private"
                            )
                          : ""}
                      </span>
                    ) : (
                      <VTPDropdown
                        className={styles.dropdown}
                        options={dropdownOptions()}
                        optionSelectedCallback={(val) => {
                          handleFileVisibilityChange(pos, Number(val));
                        }}
                        selectedValue={req.sharingOption.toString()}
                        small={true}
                      />
                    )}
                    <span
                      className={`${styles.sizeColumn} ${VTPStyles.Typography.Body.Small} ${VTPStyles.Color.Text.SecondaryColor}`}
                    >
                      {CommonUtilities.FormatFileSize(file.size)}
                    </span>
                    <div className={styles.buttonGroup}>
                      {req.notEnoughSpace ? (
                        <VTPButton
                          size={ButtonSize.Small}
                          type={ButtonType.Tertiary}
                          className={styles.fileRowButton}
                          onClick={() => handleRetry(req)}
                        >
                          <RefreshIcon className={styles.fileRowIcon} />
                        </VTPButton>
                      ) : null}
                      <VTPButton
                        className={styles.fileRowButton}
                        size={ButtonSize.Small}
                        type={ButtonType.Tertiary}
                        onClick={() => {
                          setUploadRequests((current) =>
                            current.filter((i, cPos) => cPos !== pos)
                          );
                        }}
                      >
                        <TrashbinIcon className={styles.fileRowIcon} />
                      </VTPButton>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    );
  };

  const getFileArrayFromEvent = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      return Array.from(event.target.files);
    } else return [];
  };

  const getPendingUploadSize = () => {
    return uploadRequests
      .filter((u) => u.fileTooBig !== true)
      .reduce((a, b) => a + b.file.size, 0);
  };

  const uploadSection = () => {
    return (
      <div className={styles.uploadSection}>
        <div className={`${VTPStyles.Typography.Body.Small}`}>
          <span className={`${VTPStyles.Color.Text.SecondaryColor}`}>
            {`${t("filesPage.uploadFileModal.storageUsed")}: `}
          </span>
          <span className={`${VTPStyles.Color.Text.PrimaryColor}`}>
            {`${convertBytesToGB(
              storageState.used + getPendingUploadSize()
            )}/${convertBytesToGB(storageState.total)}GB`}
          </span>
        </div>
        <div
          className={styles.filePicker}
          onDragOver={onDragOver}
          onDrop={onDrop}
        >
          <div
            className={`${VTPStyles.Typography.Body.Small} ${VTPStyles.Color.Text.SecondaryColor}`}
          >
            {t("filesPage.uploadFileModal.dragAndDrop")}
          </div>
          <input
            ref={fileInput}
            hidden={true}
            id="fileUpload"
            type="file"
            onChange={(e) => filesSelected(getFileArrayFromEvent(e))}
            onClick={(e) => {
              return ((e.target as HTMLInputElement).value = "");
            }}
            accept={allowedTypes.join(",")}
            multiple
          />
          <VTPButton
            size={ButtonSize.Small}
            type={ButtonType.Secondary}
            onClick={() => fileInput.current && fileInput.current.click()}
          >
            {t("filesPage.uploadFileModal.chooseFiles")}
          </VTPButton>
        </div>
        <div className={`${VTPStyles.Typography.Body.Small}`}>
          <span className={`${VTPStyles.Color.Text.SecondaryColor}`}>
            {`${t("filesPage.uploadFileModal.supportedFormats")}: `}
          </span>
          <span className={`${VTPStyles.Color.Text.PrimaryColor}`}>
            png, jpg, mp4, avi, pdf
          </span>
        </div>
        <div className={`${VTPStyles.Typography.Body.Small}`}>
          <span className={`${VTPStyles.Color.Text.SecondaryColor}`}>
            {`${t("filesPage.uploadFileModal.maxSize")}: `}
          </span>
          <span className={`${VTPStyles.Color.Text.PrimaryColor}`}>
            {CommonUtilities.FormatFileSize(storageState.maxFileSize)}
          </span>
        </div>
      </div>
    );
  };

  return (
    <VTPSimpleModal>
      <div className={modalStyles.closeButton}>
        <CloseButton onClick={props.onCancel} />
      </div>
      <div className={styles.container}>
        <div
          className={`
            ${VTPStyles.Typography.Headers.H2SubheaderLarge} 
            ${VTPStyles.Color.Text.PrimaryColor}
            ${styles.header}
          `}
        >
          {t("filesPage.uploadFileModal.uploadFiles")}
        </div>
        {uploadSection()}
        <div className={`${styles.modalContent}`}>{fileList()}</div>
        <div className={styles.modalButtons}>
          <VTPButton
            size={ButtonSize.Medium}
            type={ButtonType.Tertiary}
            onClick={props.onCancel}
          >
            {t("common.inputs.cancel")}
          </VTPButton>
          <VTPButton
            size={ButtonSize.Medium}
            type={ButtonType.Primary}
            disabled={
              uploadRequests.length === 0 ||
              uploadRequests.some((u) => u.isError)
            }
            onClick={() => handleFileUpload()}
          >
            <UploadIcon className={styles.uploadIcon} />
            {t("filesPage.inputs.upload")}
          </VTPButton>
        </div>
      </div>
    </VTPSimpleModal>
  );
};
export default UploadUserFilesModal;
